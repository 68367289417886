import ChatService from '../../services/ChatService'

const state = () => ({
    loadingChats: false,
    chat: null,
    chatUser: {
        // username: "Usuario Teste",
        // email: "test@email.com.br",
        // message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas ullam rem voluptatem, animi tempora expedita!Lorem ipsum dolor.",
        // send: true,
        send: false,
        username: "",
        email: "",
        message: ""
    },
})
  
const mutations = {
    SET_CHAT: (state, payload) => {
        state.chat = payload
    },
    SET_CHAT_USER: (state, payload) => {
        state.chatUser = payload
    },
    SET_STATUS_SEND_MSG: (state, boolean) => {
        state.chatUser.send = boolean
    },
    SET_LOADING_CHATS: (state, payload) => {
        state.loadingChat = payload
    },
}

const actions = {
    async createChat({commit, dispatch}, data){
        try{
            commit('SET_LOADING_CHATS', true)
            if(data == 0){
                await ChatService.create(data);
                await dispatch('sendNewChatMessage', data, { root: true });
            }

            commit('SET_STATUS_SEND_MSG', true)
            commit('SET_LOADING_CHATS', false)
        } catch (error) {
            commit('SET_LOADING_CHATS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async restartChat({commit, state}){
        const newChatUser = {
            username: state.chatUser.username,
            email: state.chatUser.email,
            message: "",
            send: false
        }
        commit('SET_CHAT_USER', newChatUser)
    },
}

const getters = {
    getChat: state => state.chat,
    getChatUser: state => state.chatUser,
    getLoadingChat: state => state.loadingChat,
}

export default {
    state,
    getters,
    mutations,
    actions
};