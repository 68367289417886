import Api from './Api'

export default {
    get(language, keyword, pageNumber, order) {
        return Api().get(`project?language=${language}&keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getOne(id) {
        return Api().get(`project/${id}`);
    },
    create(data) {
        return Api().post(`project`, data);
    },
    update(data) {
        return Api().put(`project/${data.id}`, data);
    }
}