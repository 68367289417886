import ProjectService from '../../services/ProjectService'

const state = () => ({
    loadingProjects: false,
    project: null,
    projects: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_project: false
})
  
const mutations = {
    SET_PROJECT: (state, payload) => {
        state.project = payload
    },
    SET_PROJECTS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.projects = payload.items
        }else{
            state.projects = [...state.projects, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_PROJECTS: (state, payload) => {
        state.loadingProject = payload
    },
    SET_SHOW_MODAL_PROJECT: (state, payload) => {
        state.show_modal_new_project = payload
    },
}

const actions = {
    async fetchProject({commit}, id){
        try{
            commit('SET_LOADING_PROJECTS', true)
            const response = await ProjectService.getOne(id);
            commit('SET_PROJECT', response.data)
            commit('SET_LOADING_PROJECTS', false)
        } catch (error) {
            commit('SET_LOADING_PROJECTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchProjects({commit, rootState}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_PROJECTS', true)
            const language = rootState.language.language.sigla;
            const response = await ProjectService.get(language, keyword, pageNumber, order);
            commit('SET_PROJECTS', response.data)
            commit('SET_LOADING_PROJECTS', false)
        } catch (error) {
            commit('SET_LOADING_PROJECTS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createProject({commit}, data){
        try{
            commit('SET_LOADING_PROJECTS', true)
            const response = await ProjectService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_PROJECT', false)
            commit('SET_LOADING_PROJECTS', false)
        } catch (error) {
            commit('SET_LOADING_PROJECTS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeProject({commit}, data){
        try{
            commit('SET_LOADING_PROJECTS', true)
            const response = await ProjectService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_PROJECTS', false)
        } catch (error) {
            commit('SET_LOADING_PROJECTS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getProject: state => state.project,
    getProjects: state => state.projects,
    getLoadingProject: state => state.loadingProject,
    getShowModalNewProject: state => state.show_modal_new_project
}

export default {
    state,
    getters,
    mutations,
    actions
};