import Api from './Api'

export default {
    send(mailOptions) {
        return Api().post(`/send/`, mailOptions);
    },
    sendResetPassword(mailOptions, HTML, userInput) {
        return Api().post(`/send/resetPassword`, {mailOptions, HTML, userInput});
    },
    sendChatMessage(mailOptions, HTML, data) {
        return Api().post(`/send/chatMessage`, {mailOptions, HTML, data});
    }
}