<template>
    <div class="flex overflow-hidden hover:overflow-y-auto justify-center items-center">
        <ul class="space-y-1 pt-4 p-2 text-sm justify-center items-center bg-white/20 hover:bg-white/90">
            <li v-for="item in $store.state.helpers.itensNavbar" :key="item.id">
                <router-link :to="item.url" :title="item.name" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100 justify-center">
                    <span v-if="item.name == 'Ordens'" class="relative">
                        <span v-if="getTotalOrdersPendentes" class="absolute flex h-2 w-2 -right-2 -top-1">
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                            <span class="absolute inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                        </span>
                        <svg class="h-5 w-5 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" /></svg>
                    </span>
                    <div v-else v-html="item.svg" class="scale-90"></div>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "SideNavComponnent",
    }
</script>