import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css';
import i18n from './i18n';

const app = createApp(App);

store.watch(
  (state) => state.language.language.sigla,
  (newLang) => {
    i18n.global.locale = newLang;
    i18n.global.fallbackLocale = newLang;
  }
);

app.use(i18n);
app.use(store);
app.use(router);
app.mount('#app');