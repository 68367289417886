// i18n.js
import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/\/([a-z0-9-_]+)\/([a-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      const namespace = matched[2];
      if (!messages[locale]) {
        messages[locale] = {};
      }
      messages[locale][namespace] = locales(key);
    }
  });
  return messages;
}

const savedLanguage = JSON.parse(localStorage.getItem('language')) || { sigla: 'en' };

const i18n = createI18n({
  locale: savedLanguage.sigla, // Idioma padrão
  fallbackLocale: 'en', // Idioma de fallback
  messages: loadLocaleMessages(),
});

export default i18n;
