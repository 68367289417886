// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import password from './modules/password';
import email from './modules/email';
import language from './modules/language';
import chat from './modules/chat';
import image from './modules/image';
import project from './modules/project';

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        password,
        email,
        language,
        chat,
        image,
        project
    }
})
